import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (index, uid, item) ->

  ls = new SecureLS();
  # traemos lo del ls
  actual_cart = ls.get 'cart'
  if !actual_cart
    actual_cart = @cart_items
  # traemos todos los datos del product Seleccionado
  cart_filter = actual_cart[uid].filter((e) =>
    if e.options
      (e.product_id == item.product_id && e.options.product_inventory_id == item.options.product_inventory_id)
    else
      (e.product_id == item.product_id)
  )
  for cart_items in cart_filter
    # si la cantidad del product Seleccionado es menos al limit, se pude sumar
    if cart_items.quantity < item.limit
      cart_items.quantity += 1
    else
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Número de unidades no válido (Unidades disponibles: #{item.limit})",
      })
    @cart_items = actual_cart
  ls.set 'cart', actual_cart
