# import AxiosRequest from "../../utils/axios-request"
# import SecureLS from 'secure-ls'

export default (uid, jwt = null, dev) ->

  if JSON.parse dev
    router_api = 'http://dev.allkryon.com:3006'
  else
    router_api = 'https://api.kryon.app'

  {cart_items, discount} = @
  return false if !cart_items

  parsedData = []
  parsedData.push(cart_items['joker']...) if cart_items['joker']
  parsedData.push(cart_items[uid]...) if cart_items[uid]
  paramsData = []
  for item in parsedData
    if item.type == 15
      service = {
        item_id: item.service_id,
        customer_id: item.customer_id
        quantity: 1,
        price: item.normal_price,
        total_discounts: 1 * item.total_discounts,
        total_taxes: (item.normal_price * 1) - ((item.normal_price * 1) / (1 + item.total_taxes)),
        total: item.price * 1,
        night_surcharge: item.night_surcharge,
        holiday_surcharge: item.holiday_surcharge,
        type_id: item.type,
        shipping: {
          store_id: item.store_id,
          foundation_id: parseInt(item.foundation_id),
          pickup: item.pickup,
          donate: item.donate,
          price: if item.pickup || item.donate then 0 else parseInt(item.shipping.price),
          date: item.date,
          hour: item.time_select,
          address_id: item.address_id
        }
      }
      paramsData.push(service)
    else if item.type == 16
      product = {
        item_id: item.product_id,
        quantity: item.quantity,
        price: item.price * item.quantity,
        total_discounts: item.quantity * item.total_discounts,
        total_taxes: (item.price * item.quantity) - ((item.price * item.quantity) / (1 + item.total_taxes)),
        total: item.price * item.quantity,
        type_id: item.type,
        product_inventory_id: if item.options then item.options.product_inventory_id else null,
        customer_id: item.customer_id
        shipping: {
          store_id: parseInt(item.store_id),
          foundation_id: parseInt(item.foundation_id),
          pickup: item.pickup,
          donate: item.donate,
          price: if item.pickup || item.donate then 0 else parseInt(item.shipping.price),
          date: null,
          hour: null,
          address_id: item.address_id
        }
      }
      paramsData.push(product)
  authenticity_token = $('[name="csrf-token"]')[0].content

  final_data = {
    shopping_cart: {
      cart_items: paramsData,
      promotional_code_id: if discount.id then discount.id else ''
    },
    authenticity_token
  }
  request = $.ajax(
    {
      url: "#{router_api}/api/v1/customers/#{uid}/shopping_carts/check",
      data: JSON.stringify(final_data),
      method: 'post',
      headers: { 'Authorization': "Bearer #{jwt}" }
      processData: false
      contentType: "application/json"
    }
  )
  request.done (data) ->
    window.location.replace("/customers/#{uid}/shopping_carts/#{data.shopping_cart_id}")
  request.fail (data) ->
    if data.responseJSON.redirect
      Swal.fire({
        icon: 'error'
        title: 'Error'
        text: data.responseJSON.message
        showConfirmButton: false
        timer: 3000
      }).then -> window.location.replace("/customers/#{uid}/completes_data/#{uid}/edit?idcart=#{data.responseJSON.cart_id}")
    else
      mapErrors = data.responseJSON.errors.map((e) => "<li>#{e}</li>")
      Swal.fire({
        icon: 'error',
        title: data.responseJSON.message,
        customClass: 'swal-wide',
        html: "<ul class='uk-text-left uk-list uk-list-decimal'>"+mapErrors.join(' ')+"</ul>"
      })
